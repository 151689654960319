<template>
    <v-container fluid style="padding-bottom: 100px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title class="pb-1 pt-1">
                        Filter
                    </v-card-title>
                    <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                    ></v-progress-linear>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="office"
                                    :items="offices"
                                    item-value="office_id"
                                    item-text="office"
                                    label="Office ID"
                                    @change="(event) => updateSales(event)"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                    :disabled="disabled"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="sales"
                                    :items="saless"
                                    item-value="sales_id"
                                    item-text="nama"
                                    label="Sales"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                    :disabled="disabled"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete
                                    solo
                                    v-model="customer"
                                    :loading="loadingCust"
                                    :items="items"
                                    item-value="customer_id"
                                    item-text="nama"
                                    :search-input.sync="search"
                                    cache-items
                                    hide-no-data
                                    label="Customer"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-text-field
                                    solo
                                    v-model="order_id"
                                    label="Order ID"
                                    single-line
                                    class="ma-0 pa-0"
                                    hide-details=true
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-text-field
                                    solo
                                    v-model="no_faktur"
                                    label="No Faktur"
                                    single-line
                                    class="ma-0 pa-0"
                                    hide-details=true
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_from"
                                        label="Date From"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_to"
                                        label="Date From"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="4" >
                                <v-radio-group
                                    v-model="invoice"
                                    row
                                    class="mt-3 pa-0"
                                    hide-details=true
                                >
                                    <v-radio
                                        label="Invoice Create Date"
                                        value="inv"
                                    ></v-radio>
                                    <v-radio
                                        label="Invoice Due Date"
                                        value="due"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-1" 
                                color="info" elevation="2" large @click="submit()">Search</v-btn>
                            </v-col>
                            <!-- <v-col cols="12" xs="12" sm="5" md="2" >
                                <v-btn
                                    color="error"
                                    elevation="2"
                                    large
                                    rounded
                                    class="mt-1"
                                    @click="submit()"
                                >
                                    <v-icon dark>
                                        mdi-magnify
                                    </v-icon>
                                    Search
                                </v-btn>
                            </v-col> -->
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="12" style="padding-bottom: 100px">
                <v-skeleton-loader v-if="loading_loader" :loading="loading_loader" type="card, table"></v-skeleton-loader>
                <div v-show="loading_loader === false">
                    <v-container fluid>
                        <v-row>
                            <v-card-title>Result
                            </v-card-title>

                            <v-col cols="12" xs="12" sm="12" md="12">
                                <v-card
                                    class="mx-auto"
                                >
                                    <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h5">
                                        Summary
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Piutang Report</v-list-item-subtitle>
                                    </v-list-item-content>
                                    </v-list-item>

                                    <v-card-text>
                                    <v-row align="center">
                                        <v-col
                                        class="text-h5"
                                        cols="3"
                                        >
                                         {{summaryInv.totalTagihan ? summaryInv.totalTagihan : 0}} Inv Total
                                        </v-col>
                                        <v-col cols="3">
                                            <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon>mdi-currency-usd</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-subtitle>Bill Total : <span style="float: right;">{{summaryInv.totalFaktur ? summaryInv.totalFaktur : 0}}</span></v-list-item-subtitle>
                                            </v-list-item>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon>mdi-currency-usd-off</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-subtitle>Paid Total : <span style="float: right;">{{summaryInv.totalBayar ? summaryInv.totalBayar : 0}}</span></v-list-item-subtitle>
                                            </v-list-item>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon>mdi-cash</v-icon> 
                                            </v-list-item-icon>
                                            <v-list-item-subtitle>Invoice Remaining : <span style="float: right;">{{summaryInv.totalPiutang ? summaryInv.totalPiutang : 0}}</span></v-list-item-subtitle>
                                            </v-list-item>
                                        </v-col>
                                    </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                                                        
                            <v-col cols="12" xs="12" sm="12" md="12">
                                <v-data-table
                                    :headers="headers"
                                    :items="raw_materials"
                                    :options.sync="options"
                                    :loading="loading"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="totalItems"
                                    :page.sync="pagination"
                                    page-count="10"
                                    class="elevation-1 mt-2"
                                    fixed-header 
                                    height="500"
                                >
                                    <template v-slot:[`header.NamaCustomer`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.CustomerOrderNo`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.faktur`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.tglfaktur`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.TglJTempo`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </v-col>
        </v-row>
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
            <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
            </v-layout>
            <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
            </v-btn>
        </v-snackbar>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'KBT',
                disabled: false,
                href: '/admin/srmall',
                },
                {
                text: 'KBT Report',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Piutang Report',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading:false,
            offices: [],
            office: '',
            saless:[],
            sales: '',
            customers:[],
            items:[],
            customer: '',
            loadingCust: false,
            display: 'none',
            loading_loader: false,
            sortBy: 'asc',
            sortName: '',
            raw_materials: [],
            search: null,
            headers: [
                {
                    text: 'Customer',
                    align: 'start',
                    sortable: false,
                    value: 'NamaCustomer',
                },
                { text: 'No Order', value: 'CustomerOrderNo', sortable: false },
                { text: 'Invoice', value: 'faktur', sortable: false },
                { text: 'Inv. Date', value: 'tglfaktur', sortable: false },
                { text: 'Due. Date', value: 'TglJTempo', sortable: false },
                { text: 'Inv. Total', value: 'NominalFaktur', sortable: false },
                { text: 'Bill. Total', value: 'tagihan', sortable: false },
                { text: 'Paid', value: 'bayar', sortable: false },
                { text: 'Bill Remaining', value: 'piutang', sortable: false }
            ],
            totalItems: 10,
            pagination: 1,
            options: {},
            order_id: '',
            no_faktur: '',
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            invoice: 'inv',
            summaryInv: {},
            data_var: {
                entity_id : 'KBT',
                appl_id : 'WEBKBT'
            },
            group: '',
            disabled: false
        }
    },
    mounted(){
        this.getMaterial()
        this.getEnvConf()
    },
    methods:{
        getEnvConf(){
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {

                for (let i = 0; i < res.data.data.length; i++) {

                    if (res.data.data[i]['var_id'] === 'OFFICEID') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.office = res.data.data[i]['var_value']
                            this.getMaterial()
                            this.disabled = true
                        }
                    }

                    if (res.data.data[i]['var_id'] === 'SALESID') {

                        if (res.data.data[i]['var_value'] != null) {
                            this.sales = res.data.data[i]['var_value']
                            this.updateSales()
                            this.disabled = true
                        }
                    }

                    
                }

            })
        },
        async updateSales(office){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kbt/piutang?officeid=${office ? office : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.saless = res.data.sales
            });
        },
        async querySelections (value) {
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kbt/customer?search=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.items = res.data
            });
        },
        async getMaterial(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kbt/piutang`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.offices = res.data.office
                this.saless = res.data.sales
            });
        },
        sortByFunc(sortBy){
            this.sortBy = this.sortBy === 'desc' ? 'asc' : 'desc';
            this.sortName = sortBy
            this.submit(1, 10)
        },
        async submit(page = 1, itemsPerPage = 10){
            this.loading = true
            this.loading_loader = true
            this.display = 'block'

            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''

            this.summaryOverview()

            await axios.get(`${process.env.VUE_APP_URL}/api/kbt/piutang?txtOffID=${this.office ? this.office : ''}&txtSalesId=${this.sales ? this.sales : ''}&txtCustomer=${this.customer ? this.customer : ''}&txtOrderId=${this.order_id ? this.order_id : ''}&txtFaktur=${this.no_faktur ? this.no_faktur : ''}&rdStr=${this.invoice ? this.invoice : ''}&startDate=${start_date ? start_date : ""}&endDate=${end_date ? end_date : ""}&sort=${this.sortBy ? this.sortBy : 'desc'}&sortName=${this.sortName ? this.sortName : ''}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading = false
                this.loading_loader = false

                this.raw_materials = res.data.data
                this.totalItems = res.data.total
                this.pagination = res.data.current_page
            })
        },
        async summaryOverview(){
            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''

            await axios.get(`${process.env.VUE_APP_URL}/api/kbt/piutang/summary?txtOffID=${this.office ? this.office : ''}&txtSalesId=${this.sales ? this.sales : ''}&txtCustomer=${this.customer ? this.customer : ''}&txtOrderId=${this.order_id ? this.order_id : ''}&txtFaktur=${this.no_faktur ? this.no_faktur : ''}&rdStr=${this.invoice ? this.invoice : ''}&startDate=${start_date ? start_date : ""}&endDate=${end_date ? end_date : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                console.log(res.data)
                this.summaryInv = res.data.summaryInv
            })
        }
    },
    watch: {
        options: {
        handler () {
                const { page, itemsPerPage } = this.options
                if (this.display != 'none') {
                    this.submit(page, itemsPerPage)
                }
            },
            deep: true
        },
        search (val) {
            val && val !== this.customer && this.querySelections(val)
        },
    }
}
</script>